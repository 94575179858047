const languages = [
  {
    locale: 'en',
    name: 'English',
    country: 'US',
  },
  {
    locale: 'ar',
    name: 'العربية',
    country: 'AE',
  },
]

export default languages
