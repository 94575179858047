import React from 'react'
import {
  Grid,
  Typography,
  FormHelperText,
  ListItem,
  List,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  IconButton,
} from '@mui/material'
import { useDropzone } from 'react-dropzone'
import { CloudUpload as CloudUploadIcon, Delete as DeleteIcon } from '@mui/icons-material'

const baseStyle = {
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
  textAlign: 'center',
}

const focusedStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
}

export default function DragDropFile({
  files = [],
  accept = {},
  maxSize,
  label = '',
  handleBlur = () => {},
  error = false,
  helperText = '',
  handleAcceptedFiles = () => {},
  handleRejectedFiles = () => {},
  handleFileDelete = () => {},
}) {
  return (
    <div>
      {files.length < 1 && (
        <DragDrop
          accept={accept}
          maxFiles={1}
          maxSize={maxSize}
          label={label}
          handleAcceptedFiles={handleAcceptedFiles}
          handleRejectedFiles={handleRejectedFiles}
          onBlur={handleBlur}
          error={error}
          helperText={helperText}
        />
      )}
      <FileControl
        handleFileDelete={handleFileDelete}
        files={files}
      />
    </div>
  )
}

function DragDrop(props) {
  const {
    id = Math.random(),
    label = '',
    accept = {},
    handleAcceptedFiles = () => {},
    onBlur = () => {},
    handleRejectedFiles = () => {},
    error = false,
    helperText = '',
    maxFiles = 0,
    maxSize = Infinity,
  } = props
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept,
    onDropRejected: (files) => handleRejectedFiles(files),
    onDropAccepted: (files) => handleAcceptedFiles(files),
    onFileDialogCancel: () => onBlur(),
    maxFiles,
    maxSize,
  })

  const style = React.useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  )

  return (
    <>
      <Typography>{label}</Typography>
      <Grid
        container
        flexDirection="row"
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        {...getRootProps({ style })}
      >
        <input
          name={id}
          {...getInputProps()}
        />
        <Grid
          item
          xs={12}
        >
          <CloudUploadIcon fontSize="large" />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <p>Drag & drop, or browse files</p>
        </Grid>
      </Grid>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </>
  )
}
function FileControl({ files, handleFileDelete }) {
  return (
    <List dense>
      {Object.entries(files).map(([key, value]) => (
        <ListItem
          key={key}
          disableGutters
          secondaryAction={
            <IconButton
              onClick={() => handleFileDelete(key)}
              edge="end"
              aria-label="uploading"
            >
              <DeleteIcon color="error" />
            </IconButton>
          }
        >
          <ListItemAvatar>
            {value instanceof File ? (
              <ListItemIcon>
                <img
                  alt="F"
                  src={URL.createObjectURL(value)}
                  width="84"
                  className="me-3 rounded border"
                />
              </ListItemIcon>
            ) : (
              <ListItemIcon>
                <img
                  alt="F"
                  src={value}
                  width="84"
                  className="me-3 rounded border"
                />
              </ListItemIcon>
            )}
          </ListItemAvatar>
          {value instanceof File && (
            <ListItemText
              primary={value.name}
              secondary={formatBytes(value.size)}
            />
          )}
        </ListItem>
      ))}
    </List>
  )
}
