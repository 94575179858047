import React from 'react'
import { MoreVert as MoreVertIcon } from '@mui/icons-material'
import { Box, IconButton, Menu, MenuItem } from '@mui/material'

export default function MoreMenu({ data, color = 'default', size = 'medium', items = [] }) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClose = (event) => {
    event.stopPropagation()
    setAnchorEl(null)
  }
  const handleOpen = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <IconButton
        size={size}
        onClick={handleOpen}
        color={color}
      >
        <MoreVertIcon fontSize={size} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {items.map((item, idx) => (
          <MenuItem
            disabled={item.disabled ?? false}
            key={idx}
            onClick={(event) => {
              handleClose(event)
              item.onClick(data)
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}
