import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from '@mui/material'

export default function DeleteDialog({
  confirmText = '',
  open = false,
  onConfirm = () => {},
  onClose = () => {},
}) {
  const [text, setText] = React.useState('')
  const handleClose = (e) => {
    e.stopPropagation()
    setText('')
    onClose()
  }
  const handleDelete = (e) => {
    e.stopPropagation()
    onClose()
    setText('')
    onConfirm()
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      onClick={(e) => e.stopPropagation()}
    >
      <DialogTitle>Delete confirmation</DialogTitle>
      <Divider />
      <DialogContent>
        To confirm deletion type &quot;{confirmText}&quot; in the field
        <TextField
          placeholder={confirmText}
          value={text ?? ''}
          onChange={(e) => setText(e.target.value)}
          size="small"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleClose}
        >
          cancel
        </Button>
        <Button
          color="error"
          variant="contained"
          disabled={text !== confirmText}
          onClick={handleDelete}
        >
          delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}
