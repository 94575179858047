import React from 'react'
import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import 'react-quill/dist/quill.snow.css'
import LanguageMenu from '@imports/LanguageMenu'

function HelpArticleCategoryForm({ formik }) {
  const [language, setLanguage] = React.useState('en')

  return (
    <Paper sx={{ p: 2 }}>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Main Info</Typography>
            <LanguageMenu languageState={[language, setLanguage]} />
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <TextField
            required
            fullWidth
            id="title"
            name={`title.${language}`}
            label={`Title (${language})`}
            value={formik.values.title?.[language]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <FormControl
            variant="outlined"
            error={
              (formik.touched.title?.en && Boolean(formik.errors.title?.en)) ||
              (formik.touched.title?.ar && Boolean(formik.errors.title?.ar))
            }
          >
            <FormHelperText>{formik.touched.title?.en && formik.errors.title?.en}</FormHelperText>
            <FormHelperText>{formik.touched.title?.ar && formik.errors.title?.ar}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <TextField
            fullWidth
            id="parent"
            name="parent"
            label="Parent"
            value={formik.values.parent}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.parent && Boolean(formik.errors.parent)}
            helperText={formik.touched.parent && formik.errors.parent}
            select
            disabled
          >
            <MenuItem>Website</MenuItem>
          </TextField>
        </Grid>
      </Grid>
    </Paper>
  )
}
export default HelpArticleCategoryForm
