import React from 'react'
import { Box, Button, Container, Grid, Paper, TextField, Typography } from '@mui/material'
import { AuthContext } from '@contexts/AuthContext'
import * as yup from 'yup'
import { useLogin } from '@api/auth'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import logo from './logo.png'

export function Login() {
  const navigate = useNavigate()
  const { login } = React.useContext(AuthContext)
  const loginMutation = useLogin()
  const validationSchema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required('الحقل مطلوب'),
  })
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: (values, actions) => {
      loginMutation
        .mutateAsync(values)
        .then((res) => {
          // console.log(res.data)
          login(res.data)
          actions.resetForm()
          navigate('/')
        })
        .catch((err) => console.log(err))
    },
  })

  return (
    <Container maxWidth="sm">
      <Paper sx={{ pt: 5, pb: 7, px: { xs: 2, md: 5 }, mt: 10 }}>
        <Box sx={{ textAlign: 'center', mb: 10 }}>
          <img
            src={logo}
            alt="logo"
            style={{ width: '80%', maxWidth: 250, objectFit: 'contain' }}
          />
        </Box>
        <Grid
          container
          spacing={5}
          component="form"
          onSubmit={formik.handleSubmit}
        >
          <Grid
            item
            xs={12}
          >
            <Typography
              variant="subtitle1"
              fontWeight={600}
              color="primary.light"
              gutterBottom
            >
              Email
            </Typography>
            <TextField
              size="small"
              fullWidth
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Typography
              variant="subtitle1"
              fontWeight={600}
              color="primary.light"
              gutterBottom
            >
              Password
            </Typography>
            <TextField
              size="small"
              fullWidth
              value={formik.values.password}
              name="password"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={formik.handleSubmit}
              type="submit"
              disabled={loginMutation.isLoading}
            >
              <Typography
                variant="subtitle1"
                p={0.5}
                fontWeight={600}
              >
                Login
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  )
}
export default Login
