import React from 'react'
import { CssBaseline, ThemeProvider } from '@mui/material'
import rtlPlugin from 'stylis-plugin-rtl'
import { prefixer } from 'stylis'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import { createThemeInstance } from '@contexts/ThemeProvider'
import { useTranslation } from 'react-i18next'

const languagesFont = { en: 'Cairo', ar: 'Cairo' }
function MyThemeProvider({ children }) {
  const { i18n } = useTranslation()
  const direction = i18n?.dir?.(i18n.language) ?? 'ltr'
  const cache = createCache({
    key: `mui-${direction}`,
    stylisPlugins: [...(direction === 'rtl' ? [prefixer, rtlPlugin] : [])],
  })
  document.dir = direction
  return (
    <ThemeProvider
      theme={createThemeInstance({
        direction,
        typography: {
          fontFamily: languagesFont[i18n.language] ?? languagesFont.en,
        },
      })}
    >
      <CssBaseline />
      <CacheProvider value={cache}>{children}</CacheProvider>
    </ThemeProvider>
  )
}

export default MyThemeProvider
