import { useMutation } from 'react-query'
import { httpClient } from './httpClient'

export const uploadFile = (file) => {
  const formData = new FormData()
  formData.append('file', file)
  return httpClient.post('/files/upload', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export const useUploadFile = () => useMutation((file) => uploadFile(file))
