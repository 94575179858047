import React from 'react'
import { Button } from '@mui/material'
import { Add } from '@mui/icons-material'
// import { toast } from 'react-toastify'
import RouterLink from '@components/RouterLink'
import PageLayout from '@layouts/PageLayout'
import HelpCenterCategoriesTable from './sections/HelpCenterCategoriesTable'
import HelpCenterTable from './sections/HelpCenterTable'
import HelpCenterFeedbackTable from './sections/HelpCenterFeedbackTable'

function HelpCenter() {
  return (
    <PageLayout
      title="Help Center"
      endComponent={
        <RouterLink
          to="/help-center/create"
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <Button
            variant="contained"
            startIcon={<Add />}
          >
            Create
          </Button>
        </RouterLink>
      }
    >
      <HelpCenterTable />
      <HelpCenterCategoriesTable />
      <HelpCenterFeedbackTable />
    </PageLayout>
  )
}
export default HelpCenter
