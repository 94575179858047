import React from 'react'
import { Box, Button, Divider, Grid, Typography } from '@mui/material'
import ReactTable from '@components/ReactTable'
import moment from 'moment'
import StatisticCard from '@imports/StatisticCard'
import RouterLink from '@components/RouterLink'
import useQueryState from '@components/QueryState'
import PageLayout from '@layouts/PageLayout'

const payments = [
  { title: 'Total Number of institutions', count: 16 },
  { title: 'Upcoming Payment', count: 16 },
  { title: 'Late Payment', count: 0 },
  { title: 'Notified Users', count: 0 },
]

const tableColumns = [
  {
    title: 'Name',
    accessor: 'name',
  },
  {
    title: 'Next Payment',
    id: 'next_payment',
    accessor: (rowData) => moment(rowData.next_payment).format('LL'),
  },
  {
    title: 'Plan',
    id: 'plan',
    accessor: (rowData) => (
      <Typography>
        {rowData.plan}/
        <Typography
          variant="caption"
          component="span"
        >
          {rowData.plan_duration}
        </Typography>
      </Typography>
    ),
  },
  {
    title: 'Type',
    accessor: 'type',
  },
]
export function Payments() {
  const tableData = [
    {
      name: 'Kids-Tent',
      branches: '2 branches',
      created_at: '2022-12-28T10:00:00+02:00',
      plan: 'Free Plan',
      plan_duration: 'Monthly',
      type: 'School',
    },
  ]
  const [selectedSchool, setSelectedSchool] = useQueryState('school', {
    defaultValue: '',
    type: 'string',
  })

  return (
    <PageLayout
      title="Payments"
      schoolSelectProps={{ useState: [selectedSchool, setSelectedSchool] }}
      endComponent={
        <Button variant="outlined">
          <RouterLink
            to="/payments/finance"
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            Finance
          </RouterLink>
        </Button>
      }
    >
      <Box mb={8}>
        <Typography
          variant="h6"
          gutterBottom
        >
          General
        </Typography>
        <Grid
          container
          spacing={4}
        >
          {payments.map(({ title, count }, index) => (
            <Grid
              item
              xs
              key={index}
            >
              <StatisticCard
                title={title}
                count={count}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Divider />
      <Box my={8}>
        <ReactTable
          // title="Schools"
          data={tableData}
          // isLoading={ideasQuery.isLoading}
          // isFetching={ideasQuery.isFetching}
          columns={tableColumns}
          // onRowClick={rowData => navigate('/ideas/' + String(rowData.id))}
          // metadata={ideasQuery.data?.meta}
          options={{
            enableSelection: false,
          }}
        />
      </Box>
    </PageLayout>
  )
}
export default Payments
