import juice from 'juice'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { httpClient } from './httpClient'

const handleBlogPayload = (payload) => ({
  ...payload,
  html: juice(`
<html>
  <head>
    <style>
      ${payload.css}
    </style>
  </head>
  <body>
    <div class="quill bg-white">
      <div class="ql-container ql-snow">
        <div class="ql-editor">
          ${payload.html}
        </div>
      </div>
    </div>
  </body>
</html>
`),
})
export const useBlog = (page = 1, filters = [], sortBy = []) =>
  useQuery(
    [
      'blog',
      `page=${page}`,
      `filters=${JSON.stringify(filters)}`,
      `sortBy=${JSON.stringify(sortBy)}`,
    ],
    async () => {
      const filterParams = filters.reduce(
        (prevFilters, currentFilter) => ({
          ...prevFilters,
          [currentFilter.id]: currentFilter.value,
        }),
        {}
      )
      let sort = ''
      if (sortBy.length) {
        sort = (sortBy[0].desc ? '-' : '') + sortBy[0].id
      }
      const response = await httpClient.get('/blogs', {
        params: { page, ...filterParams, ...(sort ? { sort } : {}) },
      })
      return response.data
    },
    { keepPreviousData: true }
  )

export const useBlogTopic = (id) =>
  useQuery(
    ['blog', id],
    async () => {
      const response = await httpClient.get(`/blogs/${id}`)
      return response.data
    },
    { enabled: Boolean(id) }
  )

export const useCreateBlogTopic = () => {
  const queryClient = useQueryClient()
  return useMutation((payload) => httpClient.post(`/blogs`, handleBlogPayload(payload)), {
    onSuccess: () => {
      queryClient.invalidateQueries('blog')
    },
  })
}

export const useEditBlogTopic = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, payload }) => httpClient.put(`/blogs/${id}`, handleBlogPayload(payload)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('blog')
      },
    }
  )
}

export const useDeleteBlogTopic = () => {
  const queryClient = useQueryClient()
  return useMutation((id) => httpClient.delete(`/blogs/${id}`), {
    onSuccess: () => {
      queryClient.invalidateQueries('blog')
    },
  })
}

export const useToggleTrendingBlog = () => {
  const queryClient = useQueryClient()
  return useMutation(({ id, payload }) => httpClient.patch(`/blogs/${id}`, payload), {
    onSuccess: () => {
      queryClient.invalidateQueries('blog')
    },
  })
}
