import React from 'react'
import * as yup from 'yup'
import { Box, Button, CircularProgress, Grid } from '@mui/material'
import PageLayout from '@layouts/PageLayout'
import { useFormik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-quill/dist/quill.snow.css'
import { useEditHelpCategory, useHelpCategory } from '@api/helpCenter'
import languages from '@utils/languages'
import HelpArticleCategoryForm from './sections/HelpArticleCategoryForm'

const validationSchema = yup.object().shape({
  title: yup.object().shape(
    languages.reduce(
      (prev, { locale }) => ({
        ...prev,
        [locale]: yup.string().required(`This ${locale} field is required`),
      }),
      {}
    )
  ),
})

export function EditHelpArticle() {
  const { id } = useParams()
  const helpCategoryQuery = useHelpCategory(id)
  const editHelpCategory = useEditHelpCategory()
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      title:
        helpCategoryQuery.data?.title ??
        languages.reduce((prev, { locale }) => ({ ...prev, [locale]: '' }), {}),
      parent_id: helpCategoryQuery.data?.parent_id ?? null,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, actions) => {
      editHelpCategory
        .mutateAsync({ id, payload: values })
        .then(() => {
          toast.success('Category edited successfully')
          navigate('/help-center')
        })
        .catch((err) => {
          toast.error('Failed')
          console.log(err)
        })
        .finally(() => actions.setSubmitting(false))
    },
  })

  return (
    <PageLayout
      title="Edit Help Center Article"
      backEnabled
    >
      <Box
        my={4}
        component="form"
        onSubmit={formik.handleSubmit}
      >
        {!!helpCategoryQuery.isLoading && (
          <Grid
            container
            justifyContent="center"
          >
            <CircularProgress />
          </Grid>
        )}
        {!helpCategoryQuery.isLoading && (
          <>
            <HelpArticleCategoryForm formik={formik} />
            <Box
              mt={2}
              textAlign="right"
            >
              <Button
                variant="contained"
                type="submit"
                onClick={formik.handleSubmit}
                disabled={formik.isSubmitting}
              >
                Save
              </Button>
            </Box>
          </>
        )}
      </Box>
    </PageLayout>
  )
}
export default EditHelpArticle
