import React from 'react'
import { Paper, Stack, Typography } from '@mui/material'
import { Forward } from '@mui/icons-material'

function PaymentStatsCard({ title, count, since, increment }) {
  return (
    <Paper sx={{ p: 2, minWidth: 200 }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={1}
      >
        <Typography variant="overline">{title}</Typography>
        <Stack
          direction="row"
          alignItems="flex-end"
          spacing={0.1}
        >
          <Forward
            fontSize="small"
            sx={{
              transform: `rotateZ(${increment >= 0 ? '-' : '+'}90deg)`,
            }}
            color={increment >= 0 ? 'success' : 'error'}
          />
          <Typography
            variant="caption"
            fontWeight={600}
            gutterBottom
            color={increment >= 0 ? 'success.main' : 'error.main'}
          >
            {Math.abs(increment)}%
          </Typography>
        </Stack>
      </Stack>
      <Typography
        variant="h5"
        fontWeight={700}
        gutterBottom
      >
        {count}
      </Typography>
      <Typography
        variant="caption"
        color="neutral.dark"
      >
        {since}
      </Typography>
    </Paper>
  )
}

export default PaymentStatsCard
