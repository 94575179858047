/* eslint-disable no-param-reassign */
import { getAuth } from '@contexts/AuthContext'

export default function authMiddleware(axiosInstance) {
  axiosInstance.interceptors.request.use(
    async (config) => {
      config.params = config.params ?? {}
      const token = getAuth()?.access_token
      if (token) config.headers.Authorization = `Bearer ${token}`
      return config
    },
    (err) => Promise.reject(err)
  )
}
