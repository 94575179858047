import React from 'react'
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  CircularProgress,
  Grid,
  Pagination,
  Box,
  paginationClasses,
  Stack,
} from '@mui/material'
import { blue } from '@mui/material/colors'
import {
  useTable,
  usePagination,
  useRowSelect,
  useFilters,
  useGlobalFilter,
  useSortBy,
} from 'react-table'
import { ArrowDownward, ArrowUpward } from '@mui/icons-material'
import FilterComponents, { DefaultColumnFilter } from './Filters'

function MuiCheckbox(props) {
  return (
    <Checkbox
      {...props}
      onClick={(e) => e.stopPropagation()}
      title="تحديد"
    />
  )
}

function ReactTable({
  columns,
  data,
  onRowClick,
  metadata,
  onRowsPerPageChange,
  onPageChange,
  options = {},
  isLoading = false,
  isFetching = false,
  title = '',
  initialFilters = [],
  onFilterChange,
  initialSortBy = [],
  onSortChange,
}) {
  const { enableSelection = false } = options

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  const columnsMemoized = React.useMemo(
    () =>
      columns.map((col) => ({
        ...col,
        disableFilters: col.disableFilters ?? true,
        Filter: FilterComponents[col?.filterOptions?.type ?? ''] ?? (() => {}),
        disableSortBy: col.disableSortBy ?? true,
      })),
    [columns]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: { pageIndex, pageSize, filters, sortBy },
    // canPreviousPage,
    // canNextPage,
    // pageOptions,
    pageCount,
    gotoPage,
    // nextPage,
    // previousPage,
    // setPageSize,
    // state: { pageIndex, pageSize },
  } = useTable(
    {
      useControlledState: (state) => ({
        ...state,
        pageIndex: metadata?.current_page ? (metadata?.current_page ?? 1) - 1 : 0,
        pageSize: metadata?.per_page ?? 10,
      }),
      columns: columnsMemoized,
      data,
      defaultColumn,
      initialState: {
        pageIndex: metadata?.current_page ? (metadata?.current_page ?? 1) - 1 : 0,
        pageSize: metadata?.per_page ?? 10,
        filters: initialFilters,
        sortBy: initialSortBy,
      },
      manualPagination: Boolean(metadata),
      autoResetPage: false,
      autoResetFilters: false,
      pageCount: metadata?.last_page ?? data.length,
      manualFilters: Boolean(onFilterChange),
      manualSortBy: Boolean(onSortChange),
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      // eslint-disable-next-line no-shadow
      hooks.visibleColumns.push((columns) =>
        enableSelection
          ? [
              {
                id: '__selection',
                // eslint-disable-next-line react/no-unstable-nested-components
                title: ({ getToggleAllRowsSelectedProps }) => (
                  <MuiCheckbox {...getToggleAllRowsSelectedProps()} />
                ),
                // eslint-disable-next-line react/no-unstable-nested-components
                Cell: ({ row }) => <MuiCheckbox {...row.getToggleRowSelectedProps()} />,
              },
              ...columns,
            ]
          : columns
      )
    }
  )

  React.useEffect(() => {
    if (onFilterChange) onFilterChange(filters)
  }, [filters])

  // React.useEffect(() => {
  //   if (onPageChange) onPageChange(pageIndex + 1)
  // }, [pageIndex])

  React.useEffect(() => {
    if (onRowsPerPageChange) onRowsPerPageChange(pageCount)
  }, [pageSize])

  React.useEffect(() => {
    if (onSortChange) onSortChange(sortBy)
  }, [sortBy])

  return (
    <Paper
      sx={{
        p: 1.5,
        position: 'relative',
      }}
      elevation={1}
    >
      {!!title && (
        <Typography
          variant="h5"
          color="primary.main"
          sx={{ p: 3, pt: 1.5 }}
          fontWeight={600}
        >
          {title}
        </Typography>
      )}
      {!!(isFetching && !isLoading) && (
        <Box
          sx={{
            zIndex: 10,
            position: 'absolute',
            height: '100%',
            width: '100%',
            top: 0,
            left: 0,
            backgroundColor: '#FFFFFF66',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <TableContainer>
        <Table
          sx={{ minWidth: 650 }}
          aria-label="simple table"
          {...getTableProps()}
        >
          <TableHead>
            {headerGroups.map((headerGroup, index) => (
              <TableRow
                key={index}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column, headerIdx) => (
                  <TableCell
                    key={headerIdx}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    sx={{
                      '&:hover': {
                        '& .sort-arrow-placeholder': {
                          color: '#00000055',
                        },
                      },
                    }}
                  >
                    <Stack
                      direction="row"
                      spacing={1}
                      alignItems="center"
                    >
                      <Typography
                        color="primary.dark"
                        variant="subtitle1"
                        fontWeight={700}
                        display="inline"
                      >
                        {column.render('title')}
                      </Typography>
                      {column.canSort ? (
                        column.isSorted ? (
                          column.isSortedDesc ? (
                            <ArrowDownward fontSize="inherit" />
                          ) : (
                            <ArrowUpward fontSize="inherit" />
                          )
                        ) : (
                          <ArrowUpward
                            className="sort-arrow-placeholder"
                            fontSize="inherit"
                            sx={{ color: '#00000000' }}
                          />
                        )
                      ) : (
                        ''
                      )}
                      {column.canFilter ? column.render('Filter') : null}
                    </Stack>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {!!isLoading && (
              <TableRow>
                <TableCell colSpan={columns.length + (enableSelection ? 1 : 0)}>
                  <Grid
                    container
                    justifyContent="center"
                    my={7}
                  >
                    <CircularProgress />
                  </Grid>
                </TableCell>
              </TableRow>
            )}
            {!isLoading && page.length === 0 && (
              <TableRow>
                <TableCell colSpan={columns.length + (enableSelection ? 1 : 0)}>
                  <Grid
                    container
                    justifyContent="center"
                    my={7}
                  >
                    <Typography
                      color="primary.main"
                      fontWeight={500}
                      variant="body1"
                    >
                      لا يوجد بيانات
                    </Typography>
                  </Grid>
                </TableCell>
              </TableRow>
            )}
            {!isLoading &&
              page.map((row, i) => {
                prepareRow(row)
                return (
                  <TableRow
                    key={i}
                    sx={{
                      '& th': {
                        borderBottom: 1,
                        borderBottomColor: 'rgb(224, 224, 224)',
                      },
                      '&:last-child td, &:last-child th': {
                        border: 0,
                      },
                      '&:hover': {
                        backgroundColor: onRowClick ? blue[50] : 'inherit',
                      },
                      cursor: onRowClick ? 'pointer' : 'default',
                    }}
                    onClick={() => onRowClick && onRowClick(row.original)}
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell, index) => (
                      <TableCell
                        key={index}
                        component="th"
                        scope="row"
                        {...cell.getCellProps()}
                      >
                        {cell.column.id === '__selection' || React.isValidElement(cell.value) ? (
                          cell.render('Cell')
                        ) : (
                          <Typography
                            color="primary.dark"
                            variant="body2"
                            fontWeight={600}
                          >
                            {cell.render('Cell')}
                          </Typography>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        color="primary"
        size="medium"
        sx={{
          [`&.${paginationClasses.root}`]: {
            marginTop: 3,
            justifyContent: 'center',
            display: 'flex',
          },
          [`& li button`]: {
            fontWeight: 500,
          },
        }}
        count={pageCount}
        page={pageIndex + 1}
        onChange={(_, value) => {
          if (onPageChange) onPageChange(value)
          else gotoPage(value - 1)
        }}
      />
    </Paper>
  )
}

export default ReactTable
