import React from 'react'
import { Box, Grid, Paper, Typography } from '@mui/material'
import useQueryState from '@components/QueryState'
import PageLayout from '@layouts/PageLayout'
import PaymentStatsCard from '@imports/PaymentStatsCard'
import ActivitiesSection from './sections/ActivitiesSection'
import IncomeSection from './sections/IncomeSection'

const payments = [
  { title: 'Income', count: 16, increment: 2.25, since: 'Since last month' },
  { title: 'Expenses', count: 16, increment: -1.23, since: 'Since last month' },
  { title: 'Paid Income', count: 0, increment: -7.58, since: 'Since last month' },
  { title: 'Balance', count: 0, increment: 3.72, since: 'Since last month' },
]

export function Finance() {
  const [selectedSchool, setSelectedSchool] = useQueryState('school', {
    defaultValue: 'all',
    type: 'string',
  })

  return (
    <PageLayout
      title="Finance"
      backEnabled
      schoolSelectProps={{ useState: [selectedSchool, setSelectedSchool] }}
    >
      <Box mb={4}>
        <Grid
          container
          spacing={4}
        >
          {payments.map(({ title, count, since, increment }, index) => (
            <Grid
              item
              xs
              key={index}
            >
              <PaymentStatsCard
                title={title}
                count={count}
                since={since}
                increment={increment}
              />
            </Grid>
          ))}
          <Grid
            item
            xs={12}
          >
            <Paper sx={{ py: 1, px: 1 }}>
              <Typography
                variant="h6"
                pl={2}
                py={2}
              >
                Recent activities
              </Typography>
              <ActivitiesSection />
            </Paper>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Paper sx={{ py: 1, px: 1 }}>
              <Typography
                variant="h6"
                pl={2}
                py={2}
              >
                Income Report
              </Typography>
              <IncomeSection />
            </Paper>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Paper sx={{ py: 1, px: 1 }}>
              <Typography
                variant="h6"
                pl={2}
                py={2}
              >
                Expense Report
              </Typography>
              <IncomeSection />
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </PageLayout>
  )
}
export default Finance
