import React from 'react'
import { Grid, Typography } from '@mui/material'
import StatisticCard from '@imports/StatisticCard'

function PostsSection({ data }) {
  const mobile = [
    { title: 'Number Of Posts', count: data?.posts_count ?? '-' },
    { title: 'Announcements', count: data?.text_posts_count ?? '-' },
    { title: 'Events', count: data?.event_posts_count ?? '-' },
    { title: 'Media Posts', count: data?.media_posts_count ?? '-' },
    { title: 'Activity Post', count: data?.activity_posts_count ?? '-' },
  ]

  return (
    <>
      <Typography
        variant="h6"
        gutterBottom
      >
        Posts on mobile app
      </Typography>
      <Grid
        container
        spacing={4}
      >
        {mobile.map(({ title, count }, index) => (
          <Grid
            item
            xs
            key={index}
          >
            <StatisticCard
              title={title}
              count={count}
            />
          </Grid>
        ))}
      </Grid>
    </>
  )
}
export default PostsSection
