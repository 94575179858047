import React from 'react'
import { Box, Button, Switch } from '@mui/material'
import { Add } from '@mui/icons-material'
import { toast } from 'react-toastify'
import { useConfirm } from 'material-ui-confirm'
import moment from 'moment'
import ReactTable from '@components/ReactTable'
import RouterLink from '@components/RouterLink'
import PageLayout from '@layouts/PageLayout'
import { useBlog, useDeleteBlogTopic, useToggleTrendingBlog } from '@api/blog'
import { useNavigate } from 'react-router-dom'
import useQueryState from '@components/QueryState'
import MoreMenu from './MoreMenu'

function TableActions({ rowData }) {
  const confirm = useConfirm()
  const deleteBlogTopic = useDeleteBlogTopic()
  const navigate = useNavigate()

  return (
    <MoreMenu
      data={rowData}
      items={[
        {
          label: 'Edit',
          onClick: () => {
            navigate(`/blog/${rowData.id}/edit`)
          },
        },
        {
          label: 'Clone',
          onClick: () => {
            navigate(`/blog/create`, { state: rowData })
          },
        },
        {
          label: 'Delete',
          onClick: () => {
            confirm({
              title: 'Are you sure?',
              description:
                'Are you sure your want to delete this item? this action is irreversible.',
              confirmationText: 'delete',
              cancellationText: 'cancel',
            })
              .then(() => {
                deleteBlogTopic
                  .mutateAsync(rowData.id)
                  .then(() => toast.success('Topic deleted successfully'))
                  .catch(() => toast.error('Failed'))
              })
              .catch(() => {})
          },
        },
      ]}
    />
  )
}

function IsTendingSwitch({ rowData: { id, is_trending: isTrending } }) {
  // console.log(props)
  const toggleBlogTrending = useToggleTrendingBlog()
  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <Switch
        disabled={toggleBlogTrending.isLoading}
        checked={isTrending}
        onChange={() =>
          toggleBlogTrending
            .mutateAsync({ id, payload: { is_trending: !isTrending } })
            .then(() => toast.success('Blog edited successfully'))
            .catch(() => toast.error('Failed'))
        }
      />
    </Box>
  )
}

export function Blog() {
  const navigate = useNavigate()
  const [page, setPage] = useQueryState('page', { defaultValue: 1, type: 'integer' })
  const [filters, setFilters] = React.useState([])
  const [sortBy, setSortBy] = React.useState([])
  const blogQuery = useBlog(page, filters, sortBy)

  const tableColumns = [
    {
      title: 'Id',
      accessor: 'id',
      disableFilters: false,
      disableSortBy: false,
      filterOptions: {
        type: 'text',
      },
    },
    {
      title: 'Slug',
      accessor: 'slug',
      disableFilters: false,
      disableSortBy: false,
      filterOptions: {
        type: 'text',
      },
    },
    {
      title: 'Language',
      accessor: 'language',
      disableFilters: false,
      disableSortBy: false,
      filterOptions: {
        type: 'select',
        options: [
          { label: 'All', value: '' },
          { label: 'English', value: 'en' },
          { label: 'Arabic', value: 'ar' },
        ],
      },
    },
    {
      title: 'Image',
      id: 'image',
      // eslint-disable-next-line react/no-unstable-nested-components
      accessor: (rowData) => (
        <img
          src={rowData.image}
          alt={`${rowData.title}`}
          width="70px"
        />
      ),
    },
    {
      title: 'Title',
      accessor: 'title',
      disableFilters: false,
      disableSortBy: false,
      filterOptions: {
        type: 'text',
      },
    },
    {
      title: 'Created at',
      id: 'created_at',
      accessor: (rowData) => moment(rowData.created_at).format('LL'),
      disableFilters: false,
      disableSortBy: false,
      filterOptions: {
        type: 'date',
      },
    },
    {
      title: 'Show in Trending',
      id: 'is_trending',
      // eslint-disable-next-line react/no-unstable-nested-components
      accessor: (rowData) => <IsTendingSwitch rowData={rowData} />,
      disableFilters: false,
      disableSortBy: false,
      filterOptions: {
        type: 'select',
        options: [
          { label: 'All', value: '' },
          { label: 'shown', value: true },
          { label: 'hidden', value: false },
        ],
      },
    },
    {
      title: 'Actions',
      id: 'actions',
      // eslint-disable-next-line react/no-unstable-nested-components
      accessor: (rowData) => <TableActions rowData={rowData} />,
    },
  ]
  return (
    <PageLayout
      title="Blog"
      endComponent={
        <RouterLink
          to="/blog/create"
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <Button
            variant="contained"
            startIcon={<Add />}
          >
            Create
          </Button>
        </RouterLink>
      }
    >
      <Box my={6}>
        <ReactTable
          data={blogQuery?.data?.data ?? []}
          isLoading={blogQuery.isLoading}
          isFetching={blogQuery.isFetching}
          columns={tableColumns}
          onRowClick={(rowData) => navigate(`/blog/${String(rowData.id)}/edit`)}
          metadata={blogQuery.data?.meta}
          options={{
            enableSelection: false,
          }}
          onPageChange={(newPage) => setPage(newPage)}
          initialFilters={filters}
          onFilterChange={(newFilters) => setFilters(newFilters)}
          initialSortBy={sortBy}
          onSortChange={(newSort) => setSortBy(newSort)}
        />
      </Box>
    </PageLayout>
  )
}
export default Blog
