/* eslint-disable no-shadow */
import React from 'react'
import { Menu as MenuIcon, Logout as LogoutIcon } from '@mui/icons-material'
import {
  useMediaQuery,
  useTheme,
  Box,
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  List,
  Typography,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  Grid,
  styled,
} from '@mui/material'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Logo from '@assets/logo.png'
import AuthedComponent from '@components/AuthedComponent'
import { AuthContext } from '@contexts/AuthContext'
import { navigationLinks } from './items'

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(2),
  ...theme.mixins.toolbar,
}))

const drawerWidth = 240

function MainLayout({ children }) {
  const theme = useTheme()
  const { logout } = React.useContext(AuthContext)
  const navigate = useNavigate()
  const location = useLocation()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const [open, setOpen] = React.useState(false)
  return (
    <AuthedComponent secondary={children}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          elevation={0}
          position="fixed"
          sx={{
            bgcolor: '#fff',
            width: { xs: '100%', md: `calc(100% - ${drawerWidth}px)` },
            ml: { xs: 0, md: `${drawerWidth}px` },
          }}
        >
          <Toolbar>
            <Grid
              container
              spacing={2}
              alignItems="center"
            >
              <Grid
                item
                sx={{ display: { xs: 'block', md: 'none' } }}
              >
                <IconButton onClick={() => setOpen(true)}>
                  <MenuIcon color="primary" />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography
                  noWrap
                  component="div"
                  variant="h6"
                  color="primary"
                >
                  Admin
                </Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              border: 'none',
            },
          }}
          variant={isDesktop ? 'permanent' : 'temporary'}
          anchor="left"
          open={open}
          onClose={() => setOpen(false)}
        >
          <DrawerHeader>
            <Grid
              container
              component={Link}
              href="/"
              p={1}
            >
              <img
                src={Logo}
                alt="blueassembly logo"
                width={128}
              />
            </Grid>
          </DrawerHeader>

          <Grid
            container
            spacing={2}
            flexDirection="column"
            sx={{
              height: '100%',
            }}
          >
            <Grid
              item
              xs
            >
              <List>
                {navigationLinks.map(({ title, path, Icon }, index) => {
                  const isActive =
                    (location.pathname.includes(path) && path !== '/') || path === location.pathname
                  return (
                    <ListItem
                      key={index}
                      sx={{ borderRadius: 2 }}
                    >
                      <ListItemButton
                        onClick={() => navigate(path)}
                        sx={{
                          borderRadius: 2.5,
                          bgcolor: isActive ? 'primary.main' : 'unset',
                          color: isActive ? 'white' : 'unset',
                          transition: (theme) =>
                            theme.transitions.create(['background-color', 'color'], {
                              easing: theme.transitions.easing.easeIn,
                              duration: theme.transitions.duration.leavingScreen,
                            }),
                          ':hover': {
                            bgcolor: 'primary.main',
                            color: 'white',
                          },
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            bgcolor: isActive ? 'primary.main' : 'unset',
                            color: isActive ? 'white' : 'unset',
                            minWidth: 42,
                          }}
                        >
                          <Icon />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography sx={{ fontWeight: isActive ? 600 : 400 }}>{title}</Typography>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  )
                })}
              </List>
            </Grid>
            <Grid item>
              <ListItem sx={{ borderRadius: 2 }}>
                <ListItemButton
                  sx={{
                    borderRadius: 2.5,
                    transition: (theme) =>
                      theme.transitions.create(['background-color', 'color'], {
                        easing: theme.transitions.easing.easeIn,
                        duration: theme.transitions.duration.leavingScreen,
                      }),
                    ':hover': {
                      bgcolor: 'primary.main',
                      color: 'white',
                    },
                  }}
                  onClick={logout}
                >
                  <ListItemIcon
                    sx={{
                      color: 'inherit',
                      minWidth: 42,
                    }}
                  >
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </ListItemButton>
              </ListItem>
            </Grid>
          </Grid>
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            background: '#fff',
          }}
        >
          <Toolbar />
          <Box
            sx={{
              border: '1px solid #efefef',
              borderTopLeftRadius: 16,
              background: '#f9fafb',
              height: 'calc(100% - 64px)',
              overflow: 'auto',
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </AuthedComponent>
  )
}

export default MainLayout
