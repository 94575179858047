import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { AuthContext } from '@contexts/AuthContext'

function AuthedRoute({ redirectPath = '/login', children }) {
  const { isAuthed } = React.useContext(AuthContext)
  if (!isAuthed)
    return (
      <Navigate
        to={redirectPath}
        replace
      />
    )

  return children || <Outlet />
}

export default AuthedRoute
