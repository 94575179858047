import React from 'react'
import { Box, Divider, Grid, Typography, useTheme } from '@mui/material'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
// eslint-disable-next-line import/no-unresolved
import { Doughnut } from 'react-chartjs-2'
import { Circle } from '@mui/icons-material'

ChartJS.register(ArcElement, Tooltip, Legend)

function IncomeSection() {
  const theme = useTheme()
  const items = [
    {
      label: 'Subscription',
      count: 300.75 ?? 0,
      color: theme.palette.primary.dark,
    },
    {
      label: 'Events',
      count: 834.35 ?? 0,
      color: theme.palette.error.dark,
    },
    {
      label: 'Books',
      count: 834.35 ?? 0,
      color: theme.palette.warning.light,
    },
    {
      label: 'Meals',
      count: 146.24 ?? 0,
      color: theme.palette.success.light,
    },
  ]
  const data = {
    labels: items.map((item) => item.label),
    datasets: [
      {
        label: '',
        data: [12, 19, 3, 5],
        backgroundColor: items.map((item) => item.color),
        borderWidth: 0,
      },
    ],
  }
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  }

  return (
    <Box p={4}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
        >
          <Box maxWidth={200}>
            <Doughnut
              data={data}
              options={options}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Grid
            container
            spacing={2}
          >
            {items.map(({ label, count, color }, index) => (
              <Grid
                item
                xs={12}
                key={index}
              >
                <Grid
                  container
                  spacing={1}
                  // alignItems='flex-start'
                >
                  <Grid item>
                    <Circle
                      fontSize="inherit"
                      sx={{ color, mt: 0.5 }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs
                  >
                    <Typography
                      variant="body1"
                      color={color}
                      fontWeight={700}
                    >
                      {label}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body1"
                      color="neutral.dark"
                      fontWeight={700}
                    >
                      ${count}
                    </Typography>
                  </Grid>
                  {index < items.length - 1 && (
                    <Grid
                      item
                      xs={12}
                    >
                      <Divider />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default IncomeSection
