import React from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material'

function SettingsSection({ data }) {
  const settings = [
    {
      label: 'Branches',
      value: data?.branches,
    },
    {
      label: 'Plan',
      value: '-',
    },
    {
      label: 'Mobile App',
      value: '-',
    },
    {
      label: 'Levels',
      value: data?.levels_count,
    },
    {
      label: 'Languages',
      value: '-',
    },
  ]

  return (
    <>
      <Typography
        variant="h6"
        gutterBottom
      >
        Settings
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {settings.map(({ label, value }, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Typography variant="subtitle1">{label}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">{value}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
export default SettingsSection
