import React, { useEffect } from 'react'
import { Autocomplete, FormControl, TextField } from '@mui/material'
import { useTenants } from '@api/tenants'

function SchoolSelect({ useState, disableAllOption = false }) {
  const [label, setLabel] = React.useState('')
  const tenantsQuery = useTenants({ q: label })
  const tenants = tenantsQuery.data?.data ?? []
  const schools = [
    {
      label: 'All',
      value: '',
    },
    ...tenants.map((tenant) => ({ label: tenant.name, value: tenant.id })),
  ]
  const [selectedSchool, setSelectedSchool] = useState
  useEffect(() => {
    setLabel(schools.find((school) => school.value === selectedSchool)?.label ?? '')
  }, [selectedSchool])

  return (
    <FormControl>
      <Autocomplete
        id="schools-select"
        sx={{ width: 200 }}
        options={schools}
        value={label}
        onChange={(_, option) => setSelectedSchool(option?.value || '')}
        isOptionEqualToValue={(option, value) => option.label === value}
        getOptionDisabled={(option) => option.value === '' && disableAllOption}
        onInputChange={(_, newInputValue) => setLabel(newInputValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search"
            variant="standard"
            InputProps={{ ...params.InputProps, style: { fontSize: 24 } }}
          />
        )}
      />
    </FormControl>
  )
}

export default SchoolSelect
