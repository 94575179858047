// import React from 'react'
// import { AuthContext } from '@contexts/AuthContext'
import { useMutation, useQuery } from 'react-query'
import { httpClient } from './httpClient'

export const useLogin = () => useMutation((payload) => httpClient.post('/auth/login', payload))

// export const useLogout = () => {
//   const { logout } = React.useContext(AuthContext)
//   return useMutation(() => httpClient.put('/auth/logout'), {
//     onSuccess: () => logout(),
//   })
// }

export const useUser = (initialData) =>
  useQuery(
    'user',
    async () => {
      const response = await httpClient.get('/auth/user')
      return response.data
    },
    { retry: false, initialData, enabled: false }
  )
