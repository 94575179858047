import React from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material'

const institutionTypes = [
  'School',
  'Nursery Kindergarten',
  'Extra Curricular Program',
  'University College',
  'Other',
]

function TenantInfoSection({ data }) {
  const website = [
    {
      label: 'Institution Type',
      value: data ? institutionTypes[data?.institution_type] : null,
    },
    {
      label: 'Student Count',
      value: data?.student_count,
    },
    {
      label: 'Email',
      value: data?.email,
    },
    {
      label: 'Phone',
      value: data?.phone,
    },
    {
      label: 'Country',
      value: data?.country,
    },
    {
      label: 'City',
      value: data?.city,
    },
    {
      label: 'Address',
      value: data?.address,
    },
    {
      label: 'For client',
      // eslint-disable-next-line no-nested-ternary
      value: data ? (data.for_client ? 'Yes' : 'No') : null,
    },
  ]

  return (
    <>
      <Typography
        variant="h6"
        gutterBottom
      >
        Info
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {website.map(({ label, value }, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Typography variant="subtitle1">{label}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">{value}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
export default TenantInfoSection
