import React from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material'

function WebsiteSection({ data }) {
  const website = [
    {
      label: 'URL',
      value: data?.main_url,
    },
    {
      label: 'Active Domain',
      value: data?.domains?.find((domain) => domain.status === 'active')?.domain,
    },
    {
      label: 'Other Domains',
      value: data?.domains?.map((domain) => domain.domain)?.join(', '),
    },
    {
      label: 'Theme',
      value: data?.theme?.title,
    },
    {
      label: 'Languages',
      value: '-',
    },
  ]

  return (
    <>
      <Typography
        variant="h6"
        gutterBottom
      >
        Website
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {website.map(({ label, value }, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Typography variant="subtitle1">{label}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">{value}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
export default WebsiteSection
