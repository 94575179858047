import React from 'react'
import { useSearchParams } from 'react-router-dom'
import queryTypes from './queryTypes'

function useQueryState(key, { defaultValue = '', type = 'string' }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const queryTypeHandler = queryTypes[type]
  const [filter, setFilter] = React.useState(defaultValue)
  const setQueryFilter = (value) => {
    if (searchParams.get(key) !== queryTypeHandler.serialize(value)) {
      const newValue = queryTypeHandler.serialize(value)
      searchParams.set(key, newValue)
      setSearchParams(searchParams)
    }
  }
  React.useEffect(() => {
    setFilter(queryTypeHandler.parse(searchParams.get(key), defaultValue))
  }, [queryTypeHandler.serialize(searchParams.get(key))])
  return [filter, setQueryFilter]
}

export default useQueryState
