import React from 'react'
import { Box, IconButton, Popover, TextField, MenuItem, Badge, InputAdornment } from '@mui/material'
import { Close, FilterAlt } from '@mui/icons-material'
import { debounce } from 'lodash'

function FilterLayout({ filterValue, children }) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClose = (event) => {
    setAnchorEl(null)
    event.stopPropagation()
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    event.stopPropagation()
  }
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      onKeyPress={(e) => e.stopPropagation()}
    >
      <IconButton
        size="small"
        onClick={handleClick}
      >
        <Badge
          badgeContent=" "
          color="primary"
          variant="dot"
          invisible={!filterValue}
        >
          <FilterAlt fontSize="inherit" />
        </Badge>
      </IconButton>
      <Popover
        keepMounted
        PaperProps={{ elevation: 4, variant: 'elevation' }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {children}
      </Popover>
    </div>
  )
}

function SelectFilter(rows) {
  const {
    column: { title, setFilter, filterValue, filterOptions: { options } = { options: [] } },
  } = rows
  return (
    <FilterLayout filterValue={filterValue}>
      <Box p={2}>
        <TextField
          label={title}
          value={filterValue ?? ''}
          onChange={(e) => setFilter(e.target.value)}
          size="small"
          select
          sx={{ minWidth: 200 }}
        >
          {options.map((option) => (
            <MenuItem
              value={option.value}
              key={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>
    </FilterLayout>
  )
}

function TextFilter(rows) {
  const {
    column: { title, setFilter, filterValue },
  } = rows
  const handleSearchChange = React.useCallback(
    debounce((value) => setFilter(value), 500),
    []
  )
  const textRef = React.useRef()
  React.useEffect(() => {
    if (textRef.current) textRef.current.value = filterValue ?? ''
  }, [filterValue])

  return (
    <FilterLayout filterValue={filterValue}>
      <Box p={2}>
        <TextField
          inputRef={textRef}
          label={title}
          onChange={(e) => handleSearchChange(e.target.value)}
          size="small"
          sx={{ minWidth: 200 }}
          InputProps={{
            endAdornment: filterValue && (
              <InputAdornment
                position="end"
                onClick={() => setFilter('')}
              >
                <IconButton
                  size="small"
                  edge="end"
                >
                  <Close fontSize="inherit" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </FilterLayout>
  )
}

function DateFilter(rows) {
  const {
    column: { title, setFilter, filterValue },
  } = rows
  const handleSearchChange = React.useCallback(
    debounce((value) => setFilter(value), 500),
    []
  )
  const textRef = React.useRef()
  React.useEffect(() => {
    if (textRef.current) textRef.current.value = filterValue ?? ''
  }, [filterValue])

  return (
    <FilterLayout filterValue={filterValue}>
      <Box p={2}>
        <TextField
          inputRef={textRef}
          label={title}
          type="date"
          onChange={(e) => handleSearchChange(e.target.value)}
          sx={{ width: 200 }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Box>
    </FilterLayout>
  )
}

export function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  const count = preFilteredRows?.length ?? 0
  return (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}

const FilterComponents = {
  select: SelectFilter,
  text: TextFilter,
  date: DateFilter,
}

export default FilterComponents
