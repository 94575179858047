import React from 'react'
import { useQueryClient } from 'react-query'
import { useUser } from '@api/auth'

const storageKey = 'admin'
const AuthContext = React.createContext()
function AuthContextProvider({ children }) {
  const initialUser = JSON.parse(localStorage.getItem(storageKey))
  const userQuery = useUser(initialUser)
  const queryClient = useQueryClient()

  const login = (user) => {
    localStorage.setItem(storageKey, JSON.stringify(user))
    queryClient.setQueryData('user', user)
  }

  const logout = () => {
    localStorage.removeItem(storageKey)
    // queryClient.invalidateQueries('user')
    // queryClient.removeQueries('user') // clear query data
    queryClient.resetQueries()
    queryClient.setQueryData('user', undefined)
  }

  React.useEffect(() => {
    if (initialUser) {
      userQuery.refetch()
    }
  }, [])

  return (
    <AuthContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        user: userQuery.data,
        isAuthed: Boolean(userQuery.data),
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const getAuth = () => JSON.parse(localStorage.getItem(storageKey))?.data ?? {}
export { getAuth, AuthContext, AuthContextProvider }
