import React from 'react'
import { Grid, Typography } from '@mui/material'
import StatisticCard from '@imports/StatisticCard'

function GeneralSection({ data }) {
  const general = [
    { title: 'Number Of Schools', count: data?.tenants_total ?? '-' },
    { title: 'Number Of Students', count: data?.students_count ?? '-' },
    { title: 'Number of  Teachers', count: data?.teachers_count ?? '-' },
    { title: 'Number of classes', count: data?.classes_count ?? '-' },
    { title: 'New applications', count: data?.new_applications_count ?? '-' },
  ]

  return (
    <>
      <Typography
        variant="h6"
        gutterBottom
      >
        General
      </Typography>
      <Grid
        container
        spacing={4}
      >
        {general.map(({ title, count }, index) => (
          <Grid
            item
            xs
            key={index}
          >
            <StatisticCard
              title={title}
              count={count}
            />
          </Grid>
        ))}
      </Grid>
    </>
  )
}
export default GeneralSection
