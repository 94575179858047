import React from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import { toast } from 'react-toastify'
import { useConfirm } from 'material-ui-confirm'
import moment from 'moment'
import ReactTable from '@components/ReactTable'
import { useNavigate } from 'react-router-dom'
import RouterLink from '@components/RouterLink'
import { Add } from '@mui/icons-material'
import { useDeleteHelpCategory, useHelpCategories } from '@api/helpCenter'
import MoreMenu from './MoreMenu'

function TableActions({ rowData }) {
  const confirm = useConfirm()
  const deleteHelpCategory = useDeleteHelpCategory()
  const navigate = useNavigate()

  return (
    <MoreMenu
      data={rowData}
      items={[
        {
          label: 'Edit',
          onClick: () => {
            navigate(`/help-center/categories/${rowData.id}/edit`)
          },
        },
        // {
        //   label: 'Clone',
        //   onClick: () => {
        //     navigate(`/help-center/categories/create`, { state: rowData })
        //   },
        // },
        {
          label: 'Delete',
          onClick: () => {
            confirm({
              title: 'Are you sure?',
              description:
                'Are you sure your want to delete this item? this action is irreversible.',
              confirmationText: 'delete',
              cancellationText: 'cancel',
            })
              .then(() => {
                deleteHelpCategory
                  .mutateAsync(rowData.id)
                  .then(() => toast.success('Category deleted successfully'))
                  .catch(() => toast.error('Failed'))
              })
              .catch(() => {})
          },
        },
      ]}
    />
  )
}
function HelpCenterCategoriesTable() {
  const navigate = useNavigate()
  const [page, setPage] = React.useState(1)
  const helpCategoriesQuery = useHelpCategories(page)

  const tableColumns = [
    {
      title: 'Id',
      accessor: 'id',
    },
    {
      title: 'Title',
      accessor: 'title',
    },
    {
      title: 'Parent',
      accessor: 'parent.title',
    },
    {
      title: 'Created at',
      id: 'created_at',
      accessor: (rowData) => moment(rowData.created_at).format('LL'),
    },
    {
      title: 'Actions',
      id: 'actions',
      // eslint-disable-next-line react/no-unstable-nested-components
      accessor: (rowData) => <TableActions rowData={rowData} />,
    },
  ]
  return (
    <Box my={10}>
      <Grid
        container
        sx={{ mb: 4 }}
        justifyContent="space-between"
        spacing={1}
      >
        <Grid item>
          <Typography variant="h4">Categories</Typography>
        </Grid>
        <Grid item>
          <RouterLink
            to="/help-center/categories/create"
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <Button
              variant="contained"
              startIcon={<Add />}
            >
              Create
            </Button>
          </RouterLink>
        </Grid>
      </Grid>
      <ReactTable
        // title="Categories"
        data={helpCategoriesQuery?.data?.data ?? []}
        isLoading={helpCategoriesQuery.isLoading}
        isFetching={helpCategoriesQuery.isFetching}
        columns={tableColumns}
        onRowClick={(rowData) => navigate(`/help-center/categories/${String(rowData.id)}/edit`)}
        metadata={helpCategoriesQuery.data?.meta}
        options={{
          enableSelection: false,
        }}
        onPageChange={(newPage) => setPage(newPage)}
      />
    </Box>
  )
}
export default HelpCenterCategoriesTable
