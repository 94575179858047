import React from 'react'
import { Paper, Typography } from '@mui/material'

function StatisticCard({ title, count }) {
  return (
    <Paper sx={{ p: 2, minWidth: 200 }}>
      <Typography variant="overline">{title}</Typography>
      <Typography
        variant="h4"
        color="primary.main"
      >
        {count}
      </Typography>
    </Paper>
  )
}

export default StatisticCard
