import { useMutation, useQuery, useQueryClient } from 'react-query'
import { httpClient } from './httpClient'

export const useTenants = ({ page = 1, q = '' }) =>
  useQuery(
    ['tenants', `page=${page}`, `q=${q}`],
    async () => {
      const response = await httpClient.get('/tenants', { params: { page, q } })
      return response.data
    },
    { staleTime: Infinity, keepPreviousData: true }
  )

export const useTenant = (id) =>
  useQuery(
    ['tenants', id],
    async () => {
      const response = await httpClient.get(`/tenants/${id}`)
      return response.data.data
    },
    { enabled: Boolean(id) }
  )

export const useDeleteTenant = () => {
  const queryClient = useQueryClient()
  return useMutation((id) => httpClient.delete(`/tenants/${id}`), {
    onSuccess: () => {
      queryClient.invalidateQueries('tenants')
    },
  })
}
