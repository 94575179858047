import React from 'react'
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material'
import SchoolSelect from '@imports/SchoolSelect'
import { ChevronLeft } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

function PageLayout({
  backEnabled = false,
  title = '',
  children,
  schoolSelectProps,
  endComponent,
}) {
  const navigate = useNavigate()
  return (
    <Box p={3}>
      <Grid
        container
        sx={{ mb: 4 }}
        justifyContent="space-between"
        spacing={1}
      >
        <Grid item>
          <Stack
            direction="row"
            spacing={2}
            alignItems="flex-end"
          >
            {!!backEnabled && (
              <IconButton
                size="small"
                sx={{ mx: -0.375 }}
                onClick={() => navigate(-1)}
              >
                <ChevronLeft />
              </IconButton>
            )}
            <Typography variant="h4">{title}</Typography>
            {!!schoolSelectProps && <SchoolSelect {...schoolSelectProps} />}
          </Stack>
        </Grid>
        <Grid item>{endComponent}</Grid>
      </Grid>
      {children}
    </Box>
  )
}

export default PageLayout
