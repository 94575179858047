import { ConfirmProvider } from 'material-ui-confirm'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { AuthContextProvider } from '@contexts/AuthContext'
import ScrollToTop from '@components/ScrollToTop'
import '@contexts/LanguageProvider'
import ThemeProvider from '@contexts/ThemeProvider'
import { ToastContainer } from 'react-toastify'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './index.css'
import 'react-toastify/dist/ReactToastify.css'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <AuthContextProvider>
            <ConfirmProvider
              defaultOptions={{
                cancellationButtonProps: { color: 'error', variant: 'text' },
                confirmationButtonProps: { color: 'primary', variant: 'contained' },
              }}
            >
              <ScrollToTop />
              <App />
              <ToastContainer />
            </ConfirmProvider>
          </AuthContextProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
