import {
  BarChart as AnalyticsIcon,
  School as SchoolIcon,
  Chat as MessagesIcon,
  Gavel as RolesIcon,
  Payments as PaymentsIcon,
  Settings as SettingsIcon,
  Article as ArticleIcon,
  Help as HelpIcon,
} from '@mui/icons-material'

// eslint-disable-next-line import/prefer-default-export
export const navigationLinks = [
  {
    Icon: AnalyticsIcon,
    title: 'Analytics',
    path: '/',
  },
  {
    Icon: SchoolIcon,
    title: 'Registered Schools',
    path: '/schools',
  },
  {
    Icon: PaymentsIcon,
    title: 'Payments',
    path: '/payments',
  },
  {
    Icon: RolesIcon,
    title: 'Team & Roles',
    path: '/terms',
  },
  {
    Icon: ArticleIcon,
    title: 'Blog',
    path: '/blog',
  },
  {
    Icon: HelpIcon,
    title: 'Help Center',
    path: '/help-center',
  },
  {
    Icon: MessagesIcon,
    title: 'Messages',
    path: '/messages',
  },
  {
    Icon: SettingsIcon,
    title: 'General Settings',
    path: '/settings',
  },
]
