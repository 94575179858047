import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

export default i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    languages: [
      {
        locale: 'en',
        name: 'English',
      },
      {
        locale: 'ar',
        name: 'العربية',
      },
    ],
    fallbackLng: 'ar',
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false,
    },
    useSuspense: true,
  })
