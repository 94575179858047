import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { toast } from 'react-toastify'
import { useConfirm } from 'material-ui-confirm'
import moment from 'moment'
import ReactTable from '@components/ReactTable'
import { useNavigate } from 'react-router-dom'
import { useDeleteHelpFeedback, useHelpFeedbacks } from '@api/helpCenter'
import { Check, Close } from '@mui/icons-material'
import MoreMenu from './MoreMenu'

function TableActions({ rowData }) {
  const confirm = useConfirm()
  const deleteHelpFeedback = useDeleteHelpFeedback()

  return (
    <MoreMenu
      data={rowData}
      items={[
        {
          label: 'Delete',
          onClick: () => {
            confirm({
              title: 'Are you sure?',
              description:
                'Are you sure your want to delete this item? this action is irreversible.',
              confirmationText: 'delete',
              cancellationText: 'cancel',
            })
              .then(() => {
                deleteHelpFeedback
                  .mutateAsync(rowData.id)
                  .then(() => toast.success('Feedback deleted successfully'))
                  .catch(() => toast.error('Failed'))
              })
              .catch(() => {})
          },
        },
      ]}
    />
  )
}
function HelpCenterFeedbackTable({ articleId = null }) {
  const navigate = useNavigate()
  const [page, setPage] = React.useState(1)
  const helpFeedbacksQuery = useHelpFeedbacks(page, { articleId })

  const tableColumns = [
    {
      title: 'Id',
      accessor: 'id',
    },
    {
      title: 'Feedback',
      accessor: 'feedback',
    },
    {
      title: 'useful',
      id: 'is_useful',
      // eslint-disable-next-line react/no-unstable-nested-components
      accessor: (rowData) =>
        rowData.is_useful ? <Check color="success" /> : <Close color="error" />,
    },
    {
      title: 'Article (slug)',
      accessor: 'article.slug',
    },
    {
      title: 'Created at',
      id: 'created_at',
      accessor: (rowData) => moment(rowData.created_at).format('LL'),
    },
    {
      title: 'Actions',
      id: 'actions',
      // eslint-disable-next-line react/no-unstable-nested-components
      accessor: (rowData) => <TableActions rowData={rowData} />,
    },
  ]
  return (
    <Box my={10}>
      <Grid
        container
        sx={{ mb: 4 }}
        justifyContent="space-between"
        spacing={1}
      >
        <Grid item>
          <Typography variant="h4">Feedback</Typography>
        </Grid>
      </Grid>
      <ReactTable
        data={helpFeedbacksQuery?.data?.data ?? []}
        isLoading={helpFeedbacksQuery.isLoading}
        isFetching={helpFeedbacksQuery.isFetching}
        columns={tableColumns}
        onRowClick={(rowData) => navigate(`/help-center/feedbacks/${String(rowData.id)}`)}
        metadata={helpFeedbacksQuery.data?.meta}
        options={{
          enableSelection: false,
        }}
        onPageChange={(newPage) => setPage(newPage)}
      />
    </Box>
  )
}
export default HelpCenterFeedbackTable
