import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { MainLayout } from '@layouts'
import Home from '@pages/home'
import Schools from '@pages/schools'
import Payments from '@pages/payments'
import Finance from '@pages/finance'
import AuthedRoute from '@components/AuthedRoute'
import Login from '@pages/login'
import { Blog, CreateBlog, EditBlog } from '@pages/blog'
import {
  HelpCenter,
  CreateHelpArticle,
  EditHelpArticle,
  CreateHelpCategory,
  EditHelpCategory,
} from '@pages/helpCenter'

function App() {
  return (
    <MainLayout>
      <Routes>
        <Route
          path="login"
          element={<Login />}
        />
        <Route
          path=""
          element={<AuthedRoute />}
        >
          <Route
            index
            element={<Home />}
          />
          <Route
            path="schools"
            element={<Schools />}
          />
          <Route path="/payments">
            <Route
              index
              element={<Payments />}
            />
            <Route
              path="finance"
              element={<Finance />}
            />
          </Route>
          <Route path="blog">
            <Route
              index
              element={<Blog />}
            />
            <Route
              path="create"
              element={<CreateBlog />}
            />
            <Route
              path=":id/edit"
              element={<EditBlog />}
            />
          </Route>
          <Route path="help-center">
            <Route
              index
              element={<HelpCenter />}
            />
            <Route
              path="create"
              element={<CreateHelpArticle />}
            />
            <Route
              path=":id/edit"
              element={<EditHelpArticle />}
            />
            <Route path="categories">
              <Route
                path="create"
                element={<CreateHelpCategory />}
              />
              <Route
                path=":id/edit"
                element={<EditHelpCategory />}
              />
            </Route>
          </Route>
        </Route>
      </Routes>
    </MainLayout>
  )
}

export default App
