import React from 'react'
import { Link } from 'react-router-dom'

function RouterLink({ children, ...props }) {
  return (
    <Link
      style={{ textDecoration: 'none', color: 'inherit' }}
      {...props}
    >
      {children}
    </Link>
  )
}

export default RouterLink
