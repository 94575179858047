import React from 'react'
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material'
import useQueryState from '@components/QueryState'
import ReactTable from '@components/ReactTable'
import moment from 'moment'
import PageLayout from '@layouts/PageLayout'
import { useTenant } from '@api/tenants'
import WebsiteSection from './sections/WebsiteSection'
import SettingsSection from './sections/SettingsSection'
import GeneralSection from './sections/GeneralSection'
import MobileSection from './sections/MobileSection'
import TenantInfoSection from './sections/TenantInfoSection'

const activities = [
  // {
  //   label: 'Just Now',
  //   value: 'Admin of “Kids-Tent” Just created a new Event.',
  // },
  // {
  //   label: '2 days ago',
  //   value: 'Admin of “Kids-Tent”, ahmed Khalil changed the theme of kids-Tent website.',
  // },
  // {
  //   label: '3 days ago .',
  //   value: 'Ahmed Khalil accepted new application of the  new student “Mohammed Ramzy”.',
  // },
  // {
  //   label: '3 days ago .',
  //   value: 'Ahmed Khalil accepted new application of the  new student “Mohammed Ramzy”.',
  // },
]

const tableColumns = [
  {
    title: 'Name',
    accessor: 'name',
  },
  {
    title: 'Start Date',
    id: 'start_date',
    accessor: (rowData) => moment(rowData.start_date).format('LLL'),
  },
  {
    title: 'End Date',
    id: 'end_data',
    accessor: (rowData) => moment(rowData.end_date).format('LLL'),
  },
  {
    title: 'Visibility',
    accessor: 'visibility',
  },
  {
    title: 'Type',
    accessor: 'type',
  },
]
const tableData = [
  // {
  //   name: 'Zoo Trip',
  //   branches: '2 branches',
  //   start_date: '2022-12-28T10:00:00+02:00',
  //   end_date: '2022-12-28T18:00:00+02:00',
  //   visibility: 'Entire School',
  //   type: 'Trip',
  // },
]

export function Schools() {
  const [selectedSchool, setSelectedSchool] = useQueryState('school', {
    defaultValue: null,
    type: 'string',
  })
  const tenantQuery = useTenant(selectedSchool)
  return (
    <PageLayout
      title="School"
      schoolSelectProps={{ useState: [selectedSchool, setSelectedSchool], disableAllOption: true }}
    >
      {!selectedSchool && (
        <Typography
          textAlign="center"
          variant="h4"
          sx={{ py: 5 }}
        >
          Select a school to show results
        </Typography>
      )}
      {!!selectedSchool && (
        <>
          <Box mb={8}>
            <GeneralSection data={tenantQuery.data?.statistices} />
          </Box>
          <Box mb={8}>
            <MobileSection data={tenantQuery.data?.statistices} />
          </Box>
          <Box mb={8}>
            <TenantInfoSection
              data={{ ...(tenantQuery.data?.info ?? {}), email: tenantQuery.data?.email }}
            />
          </Box>
          <Box mb={8}>
            <WebsiteSection data={tenantQuery.data} />
          </Box>
          <Box mb={8}>
            <SettingsSection data={tenantQuery.data} />
          </Box>
          <Box my={8}>
            <ReactTable
              title="Events"
              data={tableData}
              // isLoading={ideasQuery.isLoading}
              // isFetching={ideasQuery.isFetching}
              columns={tableColumns}
              // onRowClick={rowData => navigate('/ideas/' + String(rowData.id))}
              // metadata={ideasQuery.data?.meta}
              options={{
                enableSelection: false,
              }}
            />
          </Box>
          <Box mb={8}>
            <Typography
              variant="h6"
              gutterBottom
            >
              Activities
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  {activities.map(({ label, value }, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography variant="subtitle1">{label}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle1">{value}</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {!activities.length && <Typography>no items</Typography>}
          </Box>
        </>
      )}
    </PageLayout>
  )
}
export default Schools
