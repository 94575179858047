import React from 'react'
import { Grid, Typography } from '@mui/material'
import StatisticCard from '@imports/StatisticCard'

function MobileSection({ data }) {
  const mobile = [
    {
      title: 'Number Of Posts',
      count: data?.posts_count ?? '-', // 110
    },
    {
      title: 'Announcements',
      count: data?.text_posts_count ?? '-', // 15
    },
    {
      title: 'Events',
      count: data?.event_posts_count ?? '-', // 3
    },
    {
      title: 'Media Posts',
      count: data?.media_posts_count ?? '-', // 55
    },
    {
      title: 'Activity Post',
      count: data?.activity_posts_count ?? '-', // 18
    },
  ]

  return (
    <>
      <Typography
        variant="h6"
        gutterBottom
      >
        Posts on mobile app
      </Typography>
      <Grid
        container
        spacing={4}
      >
        {mobile.map(({ title, count }, index) => (
          <Grid
            item
            xs
            key={index}
          >
            <StatisticCard
              title={title}
              count={count}
            />
          </Grid>
        ))}
      </Grid>
    </>
  )
}
export default MobileSection
