import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import { merge } from 'lodash'

function createThemeInstance(theme = {}) {
  return responsiveFontSizes(
    createTheme(
      merge(
        {
          palette: {
            text: {
              primary: '#515151',
            },
            primary: {
              main: '#3664a8',
            },
            secondary: {
              main: '#e3740a',
            },
            neutral: {
              dark: '#5A5A5A',
              main: '#F3F5F7',
              contrastText: '#fff',
            },
            paper: {
              main: '#FFF',
              contrastText: '#3664a8',
            },
          },
          typography: {},
          components: {
            MuiSvgIcon: {
              styleOverrides: {
                root: {
                  'html[dir=rtl] &': {
                    transform: 'scaleX(-1)',
                  },
                },
              },
            },
          },
        },
        theme
      )
    )
  )
}

export default createThemeInstance
