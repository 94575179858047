import React from 'react'
import {
  Box,
  Divider,
  FormHelperText,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material'
import ReactQuill from 'react-quill'
import ImageUploader from 'quill-image-uploader'
import 'react-quill/dist/quill.snow.css'
import { useAllHelpCategories } from '@api/helpCenter'
import { upload } from '@utils/QuillImageUpload'

ReactQuill.Quill.register('modules/imageUploader', ImageUploader)

const toolbarOptions = [
  [{ size: ['small', false, 'large', 'huge'] }],
  ['bold', 'italic', 'underline', 'strike'],
  [{ color: [] }, { background: [] }],
  [{ font: [] }],
  [{ align: [] }, { direction: 'rtl' }],
  // ['blockquote'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  // [{ 'script': 'sub' }, { 'script': 'super' }],
  [{ indent: '-1' }, { indent: '+1' }],

  ['link', 'image', 'video'],
  ['clean'],
]

const modules = {
  toolbar: toolbarOptions,
  imageUploader: { upload },
}

function HelpArticleForm({ formik }) {
  const helpCategoriesQuery = useAllHelpCategories()
  return (
    <Grid
      container
      spacing={1.5}
    >
      <Grid
        item
        xs={12}
        md={8}
      >
        <Paper sx={{ p: 2 }}>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <TextField
                required
                fullWidth
                id="title"
                name="title"
                label="Title"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                fullWidth
                id="category_id"
                name="category_id"
                label="Category"
                value={formik.values.category_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.category_id && Boolean(formik.errors.category_id)}
                helperText={formik.touched.category_id && formik.errors.category_id}
                select
              >
                {helpCategoriesQuery.data?.data?.map((category) => (
                  <MenuItem
                    key={category.id}
                    value={category.id}
                  >
                    {category.title}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Box
                dir="ltr"
                sx={{
                  '& .ql-container.ql-snow': {
                    minHeight: 300,
                  },
                  '& .ql-editor': {
                    minHeight: 300,
                  },
                }}
              >
                <Typography
                  variant="body1"
                  gutterBottom
                >
                  Content *
                </Typography>
                <ReactQuill
                  modules={modules}
                  value={formik.values.html}
                  onChange={(text, d, s, editor) => {
                    formik.setFieldValue('html', text)
                    formik.setFieldValue('content', editor.getText())
                  }}
                  onBlur={() => formik.setFieldTouched('html', true)}
                  bounds="#ql-container"
                  id="ql-container"
                />
                <FormHelperText error={formik.touched.html && Boolean(formik.errors.html)}>
                  {formik.touched.html && formik.errors.html}
                </FormHelperText>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
      >
        <Paper sx={{ p: 2 }}>
          <Grid
            container
            spacing={1.5}
          >
            <Grid
              item
              xs={12}
            >
              <Typography variant="h6">Main Info</Typography>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                required
                fullWidth
                id="slug"
                name="slug"
                label="Slug"
                value={formik.values.slug}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.slug && Boolean(formik.errors.slug)}
                helperText={formik.touched.slug && formik.errors.slug}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                required
                fullWidth
                id="description"
                name="description"
                label="Post Description"
                multiline
                rows={4}
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.description && Boolean(formik.errors.description)}
                helperText={formik.touched.description && formik.errors.description}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Divider />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Typography variant="h6">Meta Data</Typography>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                required
                fullWidth
                id="language"
                name="language"
                label="Language"
                value={formik.values.language}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.language && Boolean(formik.errors.language)}
                helperText={formik.touched.language && formik.errors.language}
                select
              >
                <MenuItem value="en">en</MenuItem>
                <MenuItem value="ar">ar</MenuItem>
              </TextField>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                fullWidth
                id="meta_title"
                name="meta_title"
                label="Title"
                value={formik.values.meta_title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.meta_title && Boolean(formik.errors.meta_title)}
                helperText={formik.touched.meta_title && formik.errors.meta_title}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                fullWidth
                id="meta_description"
                name="meta_description"
                label="Description"
                value={formik.values.meta_description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.meta_description && Boolean(formik.errors.meta_description)}
                helperText={formik.touched.meta_description && formik.errors.meta_description}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                fullWidth
                id="meta_keywords"
                name="meta_keywords"
                label="Keywords"
                placeholder="blog, platform, education"
                value={formik.values.meta_keywords}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.meta_keywords && Boolean(formik.errors.meta_keywords)}
                helperText={formik.touched.meta_keywords && formik.errors.meta_keywords}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}
export default HelpArticleForm
