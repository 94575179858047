import React from 'react'
import * as yup from 'yup'
import { Box, Button, CircularProgress, Grid } from '@mui/material'
import PageLayout from '@layouts/PageLayout'
import { useFormik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useBlogTopic, useEditBlogTopic } from '@api/blog'
import QuillStyle from '@utils/QuillStyle'
import BlogForm from './BlogForm'
import 'react-quill/dist/quill.snow.css'

const validationSchema = yup.object().shape({
  title: yup.string().required('Title is required'),
  html: yup.string().required('Content is required'),
  slug: yup.string().required('Slug is required'),
  description: yup.string().required('Description is required'),
  image: yup.string().required('Image is required'),
  language: yup.string().required('Language is required'),
})

export function EditBlog() {
  const { id } = useParams()
  const blogQuery = useBlogTopic(id)
  const editBlogTopicMutation = useEditBlogTopic()
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      title: blogQuery.data?.title ?? '',
      html: blogQuery.data?.html ?? '',
      content: blogQuery.data?.content ?? '',
      slug: blogQuery.data?.slug ?? '',
      description: blogQuery.data?.description ?? '',
      image: blogQuery.data?.image ?? null,
      language: blogQuery.data?.language ?? 'en',
      meta_title: blogQuery.data?.meta_title ?? '',
      meta_description: blogQuery.data?.meta_description ?? '',
      meta_keywords: blogQuery.data?.meta_keywords ?? '',
      css: blogQuery.data?.css ?? QuillStyle,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, actions) => {
      editBlogTopicMutation
        .mutateAsync({ id, payload: values })
        .then(() => {
          toast.success('Blog edited successfully')
          navigate('/blog')
        })
        .catch((err) => {
          toast.error('Failed')
          console.log(err)
        })
        .finally(() => actions.setSubmitting(false))
    },
  })

  return (
    <PageLayout
      title="Edit Post"
      backEnabled
    >
      <Box
        my={4}
        component="form"
        onSubmit={formik.handleSubmit}
      >
        {!!blogQuery.isLoading && (
          <Grid
            container
            justifyContent="center"
          >
            <CircularProgress />{' '}
          </Grid>
        )}
        {!blogQuery.isLoading && (
          <>
            <BlogForm formik={formik} />
            <Box
              mt={2}
              textAlign="right"
            >
              <Button
                variant="contained"
                type="submit"
                onClick={formik.handleSubmit}
                disabled={formik.isSubmitting}
              >
                Save
              </Button>
            </Box>
          </>
        )}
      </Box>
    </PageLayout>
  )
}
export default EditBlog
