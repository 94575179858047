import juice from 'juice'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { httpClient } from './httpClient'

export const useHelpCategories = (page = 1) =>
  useQuery(
    ['help-center-categories', `page=${page}`],
    async () => {
      const response = await httpClient.get('/help-center/categories', {
        params: {
          page,
        },
      })
      return response.data
    },
    { keepPreviousData: true }
  )

export const useAllHelpCategories = () =>
  useQuery(
    ['help-center-categories'],
    async () => {
      const response = await httpClient.get('/help-center/categories', { params: { paginate: 0 } })
      return response.data
    },
    { keepPreviousData: true }
  )

export const useHelpCategory = (id) =>
  useQuery(
    ['help-center-categories', id],
    async () => {
      const response = await httpClient.get(`/help-center/categories/${id}`)
      return response.data
    },
    { enabled: Boolean(id) }
  )

export const useCreateHelpCategory = () => {
  const queryClient = useQueryClient()
  return useMutation((payload) => httpClient.post(`/help-center/categories`, payload), {
    onSuccess: () => {
      queryClient.invalidateQueries('help-center-categories')
    },
  })
}

export const useEditHelpCategory = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, payload }) => httpClient.put(`/help-center/categories/${id}`, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('help-center-categories')
      },
    }
  )
}

export const useDeleteHelpCategory = () => {
  const queryClient = useQueryClient()
  return useMutation((id) => httpClient.delete(`/help-center/categories/${id}`), {
    onSuccess: () => {
      queryClient.invalidateQueries('help-center-categories')
    },
  })
}

const handleBlogPayload = (payload) => ({
  ...payload,
  html: juice(`
<html>
  <head>
    <style>
      ${payload.css}
    </style>
  </head>
  <body>
    <div class="quill bg-white">
      <div class="ql-container ql-snow">
        <div class="ql-editor">
          ${payload.html}
        </div>
      </div>
    </div>
  </body>
</html>
`),
})

export const useHelpArticles = (page = 1, filters = [], sortBy = []) =>
  useQuery(
    [
      'help-center-articles',
      `page=${page}`,
      `filters=${JSON.stringify(filters)}`,
      `sortBy=${JSON.stringify(sortBy)}`,
    ],
    async () => {
      const filterParams = filters.reduce(
        (prevFilters, currentFilter) => ({
          ...prevFilters,
          [currentFilter.id]: currentFilter.value,
        }),
        {}
      )
      let sort = ''
      if (sortBy.length) {
        sort = (sortBy[0].desc ? '-' : '') + sortBy[0].id
      }
      const response = await httpClient.get('/help-center/articles', {
        params: { page, ...filterParams, ...(sort ? { sort } : {}) },
      })
      return response.data
    },
    { keepPreviousData: true }
  )

export const useHelpArticle = (id) =>
  useQuery(
    ['help-center-articles', id],
    async () => {
      const response = await httpClient.get(`/help-center/articles/${id}`)
      return response.data
    },
    { enabled: Boolean(id) }
  )

export const useCreateHelpArticle = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (payload) => httpClient.post(`/help-center/articles`, handleBlogPayload(payload)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('help-center-articles')
      },
    }
  )
}

export const useEditHelpArticle = () => {
  const queryClient = useQueryClient()
  return useMutation(
    ({ id, payload }) => httpClient.put(`/help-center/articles/${id}`, handleBlogPayload(payload)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('help-center-articles')
      },
    }
  )
}

export const useDeleteHelpCenter = () => {
  const queryClient = useQueryClient()
  return useMutation((id) => httpClient.delete(`/help-center/articles/${id}`), {
    onSuccess: () => {
      queryClient.invalidateQueries('help-center-articles')
    },
  })
}

export const useHelpFeedbacks = (page = 1, { articleId = null } = {}) =>
  useQuery(
    ['help-center-feedbacks', `page=${page}`, `articleId=${articleId}`],
    async () => {
      const response = await httpClient.get('/help-center/feedback', {
        params: { page, article_id: articleId },
      })
      return response.data
    },
    { keepPreviousData: true }
  )

export const useDeleteHelpFeedback = () => {
  const queryClient = useQueryClient()
  return useMutation((id) => httpClient.delete(`/help-center/feedback/${id}`), {
    onSuccess: () => {
      queryClient.invalidateQueries('help-center-feedbacks')
    },
  })
}
