import React from 'react'
import { MenuItem, TextField } from '@mui/material'
import 'react-quill/dist/quill.snow.css'
import languages from '@utils/languages'

function LanguageMenu({ languageState }) {
  const [language, setLanguage] = languageState
  return (
    <TextField
      id="language"
      name="language"
      label="Language"
      value={language}
      onChange={(e) => setLanguage(e.target.value)}
      select
      sx={{ minWidth: 100 }}
    >
      {languages.map(({ locale, name }) => (
        <MenuItem
          value={locale}
          key={locale}
        >
          {name}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default LanguageMenu
