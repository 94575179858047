import React from 'react'
import * as yup from 'yup'
import { Box, Button } from '@mui/material'
import PageLayout from '@layouts/PageLayout'
import { useFormik } from 'formik'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import QuillStyle from '@utils/QuillStyle'
import { useCreateHelpArticle } from '@api/helpCenter'
import HelpArticleForm from './sections/HelpArticleForm'
import 'react-quill/dist/quill.snow.css'

const validationSchema = yup.object().shape({
  title: yup.string().required('Title is required'),
  category_id: yup.string().required('Content is required'),
  html: yup.string().required('Content is required'),
  slug: yup.string().required('Slug is required'),
  description: yup.string().required('Description is required'),
  language: yup.string().required('Language is required'),
})

export function CreateHelpArticle() {
  const { state: initialData } = useLocation()
  const navigate = useNavigate()
  const createHelpArticle = useCreateHelpArticle()
  const formik = useFormik({
    initialValues: {
      title: initialData?.title ?? '',
      category_id: initialData?.category?.id ?? '',
      tags: initialData?.tags ?? [],
      html: initialData?.html ?? '',
      content: initialData?.content ?? '',
      slug: initialData?.slug ?? '',
      description: initialData?.description ?? '',
      language: initialData?.language ?? 'en',
      meta_title: initialData?.meta_title ?? '',
      meta_description: initialData?.meta_description ?? '',
      meta_keywords: initialData?.meta_keywords ?? '',
      css: initialData?.css ?? QuillStyle,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, actions) => {
      createHelpArticle
        .mutateAsync(values)
        .then(() => {
          toast.success('Article created successfully')
          navigate('/help-center')
        })
        .catch((err) => {
          toast.error('Failed')
          console.log(err)
        })
        .finally(() => actions.setSubmitting(false))
    },
  })
  return (
    <PageLayout
      title="New Help Center Article"
      backEnabled
    >
      <Box
        my={4}
        component="form"
        onSubmit={formik.handleSubmit}
      >
        <HelpArticleForm formik={formik} />
        <Box
          mt={2}
          textAlign="right"
        >
          <Button
            variant="contained"
            type="submit"
            onClick={formik.handleSubmit}
            disabled={formik.isSubmitting}
          >
            Create
          </Button>
        </Box>
      </Box>
    </PageLayout>
  )
}
export default CreateHelpArticle
