import React from 'react'
import { Box, Divider } from '@mui/material'
import ReactTable from '@components/ReactTable'
import moment from 'moment'
import { toast } from 'react-toastify'
import { useConfirm } from 'material-ui-confirm'
import PageLayout from '@layouts/PageLayout'
import { useDashboard } from '@api/dashboard'
import { useDeleteTenant, useTenants } from '@api/tenants'
import useQueryState from '@components/QueryState'
import { useNavigate } from 'react-router-dom'
import GeneralSection from './sections/GeneralSection'
import PostsSection from './sections/PostsSection'
import MoreMenu from './sections/MoreMenu'
import DeleteDialog from './sections/DeleteDialog'

function TableActions({ rowData }) {
  const confirm = useConfirm()
  const deleteTenantMutation = useDeleteTenant()
  const navigate = useNavigate()
  const [deleteTenantId, setDeleteTenantId] = React.useState(null)
  const [deleteOpen, setDeleteOpen] = React.useState(false)
  return (
    <>
      <DeleteDialog
        confirmText={deleteTenantId}
        open={deleteOpen}
        onConfirm={() => {
          setDeleteOpen(false)
          confirm({
            title: 'Are you sure?',
            description: 'Are you sure your want to delete this item? this action is irreversible.',
            confirmationText: 'delete',
            cancellationText: 'cancel',
          })
            .then(() => {
              deleteTenantMutation
                .mutateAsync(deleteTenantId)
                .then(() => toast.success('Tenant deleted successfully'))
                .catch(() => toast.error('Failed'))
                .finally(() => setDeleteTenantId(null))
            })
            .catch(() => setDeleteTenantId(null))
        }}
        onClose={() => {
          setDeleteOpen(false)
          setDeleteTenantId(null)
        }}
      />
      <MoreMenu
        data={rowData}
        items={[
          {
            label: 'View',
            onClick: () => {
              navigate(`/schools?school=${rowData.id}`)
            },
          },
          {
            label: 'Delete',
            onClick: () => {
              setDeleteTenantId(rowData.id)
              setDeleteOpen(true)
            },
          },
        ]}
      />
    </>
  )
}

const tableColumns = [
  {
    title: 'Id',
    accessor: 'id',
  },
  {
    title: 'Name',
    accessor: 'name',
  },
  {
    title: 'Email',
    accessor: 'email',
  },
  {
    title: 'School info',
    id: 'school_info',
    accessor: (rowData) => (rowData.skip_info ? '✅' : '❌'),
  },
  {
    title: 'date',
    id: 'created_at',
    accessor: (rowData) => moment(rowData.created_at).format('LL'),
  },
  {
    title: 'Actions',
    id: 'actions',
    // eslint-disable-next-line react/no-unstable-nested-components
    accessor: (rowData) => <TableActions rowData={rowData} />,
  },
]
export function Home() {
  const navigate = useNavigate()

  const [page, setPage] = useQueryState('page', { defaultValue: 1, type: 'integer' })
  const [filters, setFilters] = React.useState([])
  const [sortBy, setSortBy] = React.useState([])
  const tenantsQuery = useTenants({ page })
  const statisticsQuery = useDashboard()

  return (
    <PageLayout title=" All Institutions">
      <Box mb={8}>
        <GeneralSection data={statisticsQuery.data} />
      </Box>
      <Box mb={8}>
        <PostsSection data={statisticsQuery.data} />
      </Box>
      <Divider />
      <Box my={8}>
        <ReactTable
          title="Schools"
          data={tenantsQuery?.data?.data ?? []}
          isLoading={tenantsQuery.isLoading}
          isFetching={tenantsQuery.isFetching}
          columns={tableColumns}
          metadata={tenantsQuery.data?.meta}
          onRowClick={(rowData) => navigate(`/schools?school=${rowData.id}`)}
          options={{
            enableSelection: false,
          }}
          onPageChange={(newPage) => setPage(newPage)}
          initialFilters={filters}
          onFilterChange={(newFilters) => setFilters(newFilters)}
          initialSortBy={sortBy}
          onSortChange={(newSort) => setSortBy(newSort)}
        />
      </Box>
    </PageLayout>
  )
}
export default Home
