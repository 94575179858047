import { uploadFile } from '@api/file'

export const upload = (file) =>
  new Promise((resolve, reject) => {
    uploadFile(file)
      .then((res) => resolve(res.data.url))
      .catch(() => reject(Error('Upload failed')))
  })

export default {}
