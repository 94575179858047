import React from 'react'
import { useTheme } from '@mui/material'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
} from 'chart.js'
// eslint-disable-next-line import/no-unresolved
import { Line } from 'react-chartjs-2'
import moment from 'moment'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
)

function ActivitiesSection() {
  const theme = useTheme()
  const primaryColor = theme.palette.primary.main
  const secondaryColor = theme.palette.secondary.main
  const months = moment.months()
  const data = {
    labels: months,
    datasets: [
      {
        fill: true,
        lineTension: 0.25,
        label: 'Income',
        data: [60, 200, 20, 100, 200, 400, 500, 400, 600, 700, 750, 800],
        backgroundColor: `${primaryColor}55`,
      },
      {
        fill: true,
        lineTension: 0.25,
        label: 'Expense',
        data: [0, 80, 60, 200, 300, 100, 50, 75, 50, 100, 500, 600],
        backgroundColor: `${secondaryColor}55`,
      },
    ],
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        // display: false,
      },
    },
    scales: {
      y: {
        ticks: {
          color: '#000',
          beginAtZero: true,
          font: {
            family: 'Cairo',
          },
        },
      },
      x: {
        ticks: {
          color: '#000',
          beginAtZero: true,
          font: {
            size: 14,
            weight: 600,
            family: 'Cairo',
          },
        },
      },
    },
  }
  return (
    <Line
      options={options}
      data={data}
    />
  )
}

export default ActivitiesSection
