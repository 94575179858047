import React from 'react'
import * as yup from 'yup'
import { Box, Button } from '@mui/material'
import PageLayout from '@layouts/PageLayout'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useCreateHelpCategory } from '@api/helpCenter'
import languages from '@utils/languages'
import HelpArticleCategoryForm from './sections/HelpArticleCategoryForm'
import 'react-quill/dist/quill.snow.css'

const validationSchema = yup.object().shape({
  title: yup.object().shape(
    languages.reduce(
      (prev, { locale }) => ({
        ...prev,
        [locale]: yup.string().required(`This ${locale} field is required`),
      }),
      {}
    )
  ),
})

export function CreateHelpCategory() {
  const navigate = useNavigate()
  const createHelpCategory = useCreateHelpCategory()
  const formik = useFormik({
    initialValues: {
      title: languages.reduce((prev, { locale }) => ({ ...prev, [locale]: '' }), {}),
      parent_id: null,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, actions) => {
      createHelpCategory
        .mutateAsync(values)
        .then(() => {
          toast.success('Category created successfully')
          navigate('/help-center')
        })
        .catch((err) => {
          toast.error('Failed')
          console.log(err)
        })
        .finally(() => actions.setSubmitting(false))
    },
  })
  return (
    <PageLayout
      title="New Help Center Category"
      backEnabled
    >
      <Box
        my={4}
        component="form"
        onSubmit={formik.handleSubmit}
      >
        <HelpArticleCategoryForm formik={formik} />
        <Box
          mt={2}
          textAlign="right"
        >
          <Button
            variant="contained"
            type="submit"
            onClick={formik.handleSubmit}
            disabled={formik.isSubmitting}
          >
            Create
          </Button>
        </Box>
      </Box>
    </PageLayout>
  )
}
export default CreateHelpCategory
