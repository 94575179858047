const queryTypes = {
  string: {
    parse: (query, defaultValue) => (query !== null ? query : defaultValue),
    serialize: (value) => value,
  },
  integer: {
    parse: (query, defaultValue) => (query !== null ? parseInt(query, 10) : defaultValue),
    serialize: (value) => value,
  },
  array: {
    parse: (query, defaultValue) => (query !== null ? JSON.parse(query) : defaultValue),
    serialize: (values) => JSON.stringify(values ?? []),
  },
}

export default queryTypes
