import React from 'react'
import { Box } from '@mui/material'
import { toast } from 'react-toastify'
import { useConfirm } from 'material-ui-confirm'
import moment from 'moment'
import ReactTable from '@components/ReactTable'
import { useNavigate } from 'react-router-dom'
import { useHelpArticles, useDeleteHelpCenter, useAllHelpCategories } from '@api/helpCenter'
import MoreMenu from './MoreMenu'

function TableActions({ rowData }) {
  const confirm = useConfirm()
  const deleteHelpCenter = useDeleteHelpCenter()
  const navigate = useNavigate()

  return (
    <MoreMenu
      data={rowData}
      items={[
        {
          label: 'Edit',
          onClick: () => {
            navigate(`/help-center/${rowData.id}/edit`)
          },
        },
        {
          label: 'Clone',
          onClick: () => {
            navigate(`/help-center/create`, { state: rowData })
          },
        },
        {
          label: 'Delete',
          onClick: () => {
            confirm({
              title: 'Are you sure?',
              description:
                'Are you sure your want to delete this item? this action is irreversible.',
              confirmationText: 'delete',
              cancellationText: 'cancel',
            })
              .then(() => {
                deleteHelpCenter
                  .mutateAsync(rowData.id)
                  .then(() => toast.success('Article deleted successfully'))
                  .catch(() => toast.error('Failed'))
              })
              .catch(() => {})
          },
        },
      ]}
    />
  )
}
function HelpCenterTable() {
  const navigate = useNavigate()
  const [page, setPage] = React.useState(1)
  const [filters, setFilters] = React.useState([])
  const [sortBy, setSortBy] = React.useState([])
  const helpArticlesQuery = useHelpArticles(page, filters)
  const helpCategoriesQuery = useAllHelpCategories()

  const tableColumns = [
    {
      title: 'Id',
      accessor: 'id',
      disableFilters: false,
      disableSortBy: false,
      filterOptions: {
        type: 'text',
      },
    },
    {
      title: 'Slug',
      accessor: 'slug',
      disableFilters: false,
      disableSortBy: false,
      filterOptions: {
        type: 'text',
      },
    },
    {
      title: 'Title',
      accessor: 'title',
      disableFilters: false,
      disableSortBy: false,
      filterOptions: {
        type: 'text',
      },
    },
    {
      title: 'Language',
      accessor: 'language',
      disableFilters: false,
      disableSortBy: false,
      filterOptions: {
        type: 'select',
        options: [
          { label: 'All', value: '' },
          { label: 'English', value: 'en' },
          { label: 'Arabic', value: 'ar' },
        ],
      },
    },
    {
      title: 'Category',
      id: 'category_id',
      accessor: (rowData) => rowData.category.title,
      disableFilters: false,
      disableSortBy: false,
      filterOptions: {
        type: 'select',
        options: [
          { label: 'All', value: '' },
          ...(helpCategoriesQuery.data?.data?.map((category) => ({
            label: category.title,
            value: category.id,
          })) ?? []),
        ],
      },
    },
    {
      title: 'Feedback (useful, not-useful)',
      id: 'useful',
      accessor: (rowData) => `${rowData.useful}, ${rowData.not_useful}`,
    },
    {
      title: 'Created at',
      id: 'created_at',
      accessor: (rowData) => moment(rowData.created_at).format('LL'),
      disableFilters: false,
      disableSortBy: false,
      filterOptions: {
        type: 'date',
      },
    },
    {
      title: 'Actions',
      id: 'actions',
      // eslint-disable-next-line react/no-unstable-nested-components
      accessor: (rowData) => <TableActions rowData={rowData} />,
    },
  ]
  return (
    <Box>
      <ReactTable
        data={helpArticlesQuery?.data?.data ?? []}
        isLoading={helpArticlesQuery.isLoading}
        isFetching={helpArticlesQuery.isFetching}
        columns={tableColumns}
        onRowClick={(rowData) => navigate(`/help-center/${String(rowData.id)}/edit`)}
        metadata={helpArticlesQuery.data?.meta}
        options={{
          enableSelection: false,
        }}
        onPageChange={(newPage) => setPage(newPage)}
        initialFilters={filters}
        onFilterChange={(newFilters) => setFilters(newFilters)}
        initialSortBy={sortBy}
        onSortChange={(newSort) => setSortBy(newSort)}
      />
    </Box>
  )
}
export default HelpCenterTable
