import React from 'react'
import * as yup from 'yup'
import { Box, Button, CircularProgress, Grid } from '@mui/material'
import PageLayout from '@layouts/PageLayout'
import { useFormik } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import QuillStyle from '@utils/QuillStyle'
import { useEditHelpArticle, useHelpArticle } from '@api/helpCenter'
import HelpArticleForm from './sections/HelpArticleForm'
import 'react-quill/dist/quill.snow.css'
import HelpCenterFeedbackTable from './sections/HelpCenterFeedbackTable'

const validationSchema = yup.object().shape({
  title: yup.string().required('Title is required'),
  category_id: yup.string().required('Content is required'),
  html: yup.string().required('Content is required'),
  slug: yup.string().required('Slug is required'),
  description: yup.string().required('Description is required'),
  language: yup.string().required('Language is required'),
})

export function EditHelpArticle() {
  const { id } = useParams()
  const helpArticleQuery = useHelpArticle(id)
  const editHelpArticle = useEditHelpArticle()
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: {
      title: helpArticleQuery.data?.title ?? '',
      category_id: helpArticleQuery?.data?.category?.id ?? '',
      tags: helpArticleQuery?.data?.tags ?? [],
      html: helpArticleQuery.data?.html ?? '',
      content: helpArticleQuery.data?.content ?? '',
      slug: helpArticleQuery.data?.slug ?? '',
      description: helpArticleQuery.data?.description ?? '',
      language: helpArticleQuery.data?.language ?? 'en',
      meta_title: helpArticleQuery.data?.meta_title ?? '',
      meta_description: helpArticleQuery.data?.meta_description ?? '',
      meta_keywords: helpArticleQuery.data?.meta_keywords ?? '',
      css: helpArticleQuery.data?.css ?? QuillStyle,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, actions) => {
      editHelpArticle
        .mutateAsync({ id, payload: values })
        .then(() => {
          toast.success('Article edited successfully')
          navigate('/help-center')
        })
        .catch((err) => {
          toast.error('Failed')
          console.log(err)
        })
        .finally(() => actions.setSubmitting(false))
    },
  })

  return (
    <PageLayout
      title="Edit Help Center Article"
      backEnabled
    >
      <Box
        my={4}
        component="form"
        onSubmit={formik.handleSubmit}
      >
        {!!helpArticleQuery.isLoading && (
          <Grid
            container
            justifyContent="center"
          >
            <CircularProgress />{' '}
          </Grid>
        )}
        {!helpArticleQuery.isLoading && (
          <>
            <HelpArticleForm formik={formik} />
            <Box
              mt={2}
              textAlign="right"
            >
              <Button
                variant="contained"
                type="submit"
                onClick={formik.handleSubmit}
                disabled={formik.isSubmitting}
              >
                Save
              </Button>
            </Box>
            <HelpCenterFeedbackTable articleId={id} />
          </>
        )}
      </Box>
    </PageLayout>
  )
}
export default EditHelpArticle
